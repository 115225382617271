import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Success = () => (
  <Layout>
    <SEO title="Message Successful" />
    <div style={{fontFamily: `open sans`, fontSize: `16px`, textAlign: `justify`,}}>
      <h3>Thank you!</h3>
      <p>Your message has been received successfully!</p>
    </div>
  </Layout>
)

export default Success

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony frontmatter